/**
 *
 * @param {string} dbName
 * @param {string} tableName
 * @returns Promise
 */
export const createIndexedDB = (dbName, tableName, keyPath = 'id') => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);
  
      request.onsuccess = (event) => {
        const db = event.target.result
        if (db.objectStoreNames.contains(tableName)) {
          resolve(event.target.result);
        }
      };
  
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(tableName)) {
          db.createObjectStore(tableName, { keyPath: keyPath });
          resolve(db)
        }
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @returns
   */
  const openIndexedDB = (dbName) => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @param {{id: string}} row
   * @returns
   */
  export const create = async (dbName, tableName, row) => {
    const db = await openIndexedDB(dbName, tableName)
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readwrite');
      const store = transaction.objectStore(tableName);
      const request = store.add(row);
  
      request.onsuccess = () => {
        resolve("Data added successfully.");
      };
  
      request.onerror = (event) => {
        reject("Error adding data: " + event.target.errorCode);
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @param {Array<{id: string}>} rows
   * @returns
   */
  export const insert = async (dbName, tableName, rows) => {
    const db = await openIndexedDB(dbName)
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readwrite');
      const store = transaction.objectStore(tableName);
  
      const errors = [];
  
      rows.forEach((data, index) => {
        const request = store.put(data); // use put for duplicate key in some case
  
        request.onerror = (event) => {
          errors.push(`Error inserting item at index ${index}: ${event.target.error}`);
        };
      });
  
      transaction.oncomplete = () => {
        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve("Batch insert completed successfully.");
        }
      };
  
      transaction.onerror = () => {
        reject("Transaction error: " + transaction.error);
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @param {string} id
   * @returns
   */
  export const findOne = async (dbName, tableName, id) => {
    if(!id) return null
    const db = await openIndexedDB(dbName, tableName)
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readonly');
      const store = transaction.objectStore(tableName);
      const request = store.get(id);
  
      request.onsuccess = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          resolve(null);
        }
      };
  
      request.onerror = (event) => {
        reject("Error finding data: " + event.target.errorCode);
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @returns
   */
  export const truncate = async (dbName, tableName) => {
    const db = await openIndexedDB(dbName, tableName)
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readwrite');
      const store = transaction.objectStore(tableName);
  
      const request = store.clear();
  
      request.onsuccess = () => {
        resolve(`All data in store '${tableName}' has been cleared.`);
      };
  
      request.onerror = (event) => {
        reject("Error clearing data: " + event.target.errorCode);
      };
  
      transaction.onerror = (event) => {
        reject("Transaction error: " + event.target.errorCode);
      };
    });
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @returns
   */
  export const getAll = async (dbName, tableName) => {
    const db = await openIndexedDB(dbName, tableName)
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readonly');
      const store = transaction.objectStore(tableName);
  
      const request = store.getAll()
  
      request.onsuccess = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          resolve([]);
        }
      };
  
      request.onerror = (event) => {
        reject('Error retrieving records:', event.error);
      };
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(tableName)) {
          db.createObjectStore(tableName, { keyPath: keyPath });
          resolve(db)
        }
      };
    })
  }
  
  /**
   *
   * @param {string} dbName
   * @param {string} tableName
   * @param {Array<string>} ids
   * @returns
   */
  export const findMany = async (dbName, tableName, ids, isSpliceEmpty = false) => {
    const db = await openIndexedDB(dbName, tableName)
  
    return new Promise((resolve, reject) => {
      const records = [];
  
      const transaction = db.transaction([tableName], 'readonly');
      const store = transaction.objectStore(tableName);
      for (const id of ids) {
        if(!id) {
          records.push('')
        } else {
          const request = store.get(id);
          request.onsuccess = (event) => {
            if (event.target.result) {
              records.push(event.target.result);
            } else {
              if(!isSpliceEmpty) {
                records.push('')
              }
            }
          }
        }
      }
  
      transaction.oncomplete = function () {
        resolve(records)
        // You can now use `records`, which is an array of fetched entries
      };
  
      transaction.onerror = function (event) {
        reject("Error findMany: " + event.error);
      };
    })
  }
  
  // export const hasTable = async(tableName) => {
  //   const db = await this.openDB();
  //   return db.objectStoreNames.contains(tableName);
  // }
  
  export const hasData = async(dbName, tableName) => {
    const db = await openIndexedDB(dbName, tableName)
    if(!db.objectStoreNames.contains(tableName)) return false
  
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([tableName], 'readonly');
      const store = transaction.objectStore(tableName);
      const request = store.count();
  
      request.onerror = () => reject('Error checking data');
      request.onsuccess = () => resolve(request.result);
    });
  }
  
export const dropDatabase = (dbName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(dbName);

    request.onsuccess = () => {
      resolve(`Database "${dbName}" deleted successfully.`);
    };

    request.onerror = (event) => {
      reject(`Error deleting database:`, event.target.error);
    };

    request.onblocked = () => {
      reject(`Delete request is blocked. Close all connections to the database.`);
    };
  });
};
