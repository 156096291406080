export const ROUTES = {
  DASHBOARD: '/',
  AUTH: '/auth',
  LOGIN: 'login',
  VERIFY_OTP: 'verify-otp',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  FORGOT_PASSWORD: 'forgot-password',
  REFRESH_TOKEN: 'refresh-token',
  CHANGE_PASSWORD_USER: '/change-password',
  SETTING: '/setting',
  USER_MANAGEMENT: '/managements/users',
  VIEWER_MANAGEMENT: '/managements/viewer',
  FACILITY_MANAGEMENT: '/managements/facility',
  EMISSIONS: '/emissions',
  LIST_EMISSIONS: '/emissions/list',
  CREATE_DATA_CUSTOMIZE: '/emissions/create-data-customize',
  REGISTER_DATA: 'register/:scope/:category',
  LIST_EMISSION_SUBMITTED: 'list-submitted',
  LIST_EMISSION_TEMPLATES: 'list-templates',
  LIST_EMISSION_REGISTER: 'list-menu',
  LIST_EMISSION_TEMPORARY: 'list-temporary',
  LIST_APPROVAL: 'approval',
  APPROVAL: '/approval',
  PRIMARY_DATA_MERGE: '/primary-data',
  PRIMARY_DATA_MERGE_DETAIL: 'primary-data',
  FORM_PRIMARY_DATA_MERGE: '/primary-data/list-form',
  VIEW_DATA: '/emissions/view/:scope/:category',
  SCORE_SUMMARY: 'view/score-summary',
  HOME_DASHBOARD: '/',
  CREATE_DB_CUSTOMIZE: 'create-db-customize',
  OTHERS: '/others',
  OFFSET_TRANSACTION: 'offset-transaction',
  PRIVACY: 'privacy',
  TERM_OF_USER: 'term-of-use',
  CALCULATION_TERM: '/emissions/calculation-term',
  EMISSIONS_BY_BASE_CATEGORY: 'emission-by-base-category',
  EMISSIONS_BY_BASE_PERIOD: 'emission-by-base-period',
  CONTACT: '/contact',
  LIST_MENU_EMISSION: 'list-menu',
  VIEW_EMISSION: '/emissions/view',
  CASBEE: '/emissions/casbee',
  MONITORING: '/emissions/casbee/monitoring',
  CASBEE_DETAIL: '/emissions/casbee/view/:id',
  EXPORT_REPORT: '/export-report',
  PRODUCTS: '/products',
  PRODUCTS_NEW_REGISTER: 'register',
  PRODUCTS_LIST_TEMPLATES: 'list-templates',
  PRODUCTS_LIST_SUBMITTED: 'list-submitted',
  PRODUCTS_LIST_APPROVAL: 'list-approval',
  PRODUCTS_LIST_EMISSIONS: 'list-products',
  TYPE_SELECTION: '/type-selection',
  REGISTER_PRIMARY_DATA: '/supplier/register-data',
  REGISTER_PRIMARY_PRODUCT_DATA: '/supplier/product/register-data',
  SETTING_LANGUAGE_SUPPLIER: '/supplier/setting-language',
  LIST_PRIMARY_DATA: 'list-data',
  SUPPLIER: '/supplier',
  REGISTER_USER_GROUP: '/register',
  EDIT_USER_GROUP: '/detail/:groupId',
  DETAIL_USER_GROUP: 'detail',
  GROUPS_MANAGEMENT: '/managements/groups',
  REDUCTION_TARGET_LIST_MANAGEMENT: '/managements/list-reduction-target',
  CREATE_REDUCTION_TARGET: '/managements/list-reduction-target/create-reduction-target',
  LIST_PRODUCTS: 'list-data-products',
  PRODUCT_REDUCTION: '/reduction',
  PRODUCT_REDUCTION_SETTING: '/reduction/setting',
  SETTING_LANGUAGE: '/setting-language',
  LIST_IDEA_DB_SOURCE: '/managements/db-sources',
  REGISTER_DB_SOURCE: '/managements/db-sources/register',
  CREATE_FORM_LINK_PRIMARY_DATA: '/link-to-primary/create-form',
  PRODUCT_CREATE_FORM_LINK_PRIMARY_DATA: 'link-to-primary/create-form',
  PRODUCT_PRIMARY_DATA_MERGE: 'primary-data',
  PRODUCT_FORM_PRIMARY_DATA_MERGE: 'primary-data/list-form',
  EDIT_FORM_LINK_PRIMARY_DATA: '/link-to-primary/edit-form/:id',
  PRODUCT_EDIT_FORM_LINK_PRIMARY_DATA: '/link-to-primary/edit-form/:id',
  CONTACT_SUPPLIER: '/supplier/contact',
  SETTING_AUTO_APPROVAL: '/managements/auto-approval',
  SETTING_TARGET: '/reduction/setting-target',
  SETTING_REDUCTION_INTEGRATE: '/reduction/setting-integrate',
  GROUP_ENTERPRISE_MANAGEMENT: '/group-enterprise',
  VERSIONS: 'versions',
  LINKED_SERVICE_MANAGEMENT: '/linked-service-management',
  LINKED_SERVICE_MANAGEMENT_REGISTER: '/linked-service-management/register',
  LIBRARY_BASIC_UNIT: '/library-basic-unit',
  // router for emissions product
  PRODUCTS_EMISSION: '/product-emission',
  REGISTER_PRODUCT: 'register-product',
  REGISTER_EMISSIONS: 'register-emissions/:processId',
  PRODUCT_LIST: 'products-list',
  DETAIL_PRODUCT: 'product-detail/:id',
  PRODUCT_SUBMITTED_LIST: 'submitted-list',
  PRODUCT_APPROVED_LIST: 'approved-list',
  PRODUCT_EMISSIONS_DETAIL: 'emissions-detail/:emissionId',
  CSV_FORMAT: '/managements/csv-format',
  SETTING_DISPLAY_FORMAT_NUMBER: '/managements/setting-format-number',
  PRODUCT_PROCESS_DATA_LINK: '/process-data-link/:processId',
  PRODUCT_PROCESS_DATA_LINK_PATH: '/product-emission/process-data-link',
  PRODUCT_DASHBOARD: 'dashboard',
  REGISTER_DETAIL_TEMPLATE: "register/v2/:scope/:category",
  REGISTER_PRIMARY_EMISSIONS_PRODUCT_DATA: '/supplier/product/register-data/emision',
};
