<template>
   <div class="header-switch" :style="{'left': isMobile ? 'unset' : !isExpanded ? '70px' : '236px'}" v-click-outside="handleCloseSwitchType"
    >
      <div class="type-selection"
        :class="{'focus-btn': showTypeSelectionPulldown}"
        @click="showTypeSelectionPulldown = !showTypeSelectionPulldown">
        <div class="d-flex align-center">
          <img class="type-selection-icon" :src="require(`@/assets/icons/type-selection/${typeSelection.icon}`)" alt="" />
          <div class="type-selection-text">{{$t(typeSelection.name)}}</div> 
        </div>
        <img class="type-selection-arrow-down btn-icon" :src="require('@/assets/icons/type-selection/arrow-down.svg')" alt="" :class="{'deg-180': showTypeSelectionPulldown}" />
        <img class="type-selection-arrow-down btn-icon_active" :src="require('@/assets/icons/type-selection/arrow-down_active.svg')" alt="" :class="{'deg-180': showTypeSelectionPulldown}" />
      </div>
      <div v-if="showTypeSelectionPulldown" class="type-selection-items">
        <div
          v-for="(item, index) in typeSelectionList"
          :key="index"
          class="item"
          :class="{'disabled': isDisabledProduct(item)}"
          @click.prevent="updateTypeSelection(item)"
        >
          <img :src="require(`@/assets/icons/type-selection/${item.icon}`)" alt="" />
          <div class="type-selection-text">{{$t(item.name)}}</div> 
          <img v-if="typeSelection.name === item.name" class="checked-icon" :src="require('@/assets/icons/type-selection/checked-icon.svg')" alt="">
        </div>
      </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { TYPE_SELECTION } from '@/constants/products/define-data';
import { ROUTES } from "@/router/constants";
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showTypeSelectionPulldown: false,
      typeSelection: {},
      typeSelectionList: TYPE_SELECTION,
    }
  },
  created() {
    this.typeSelection = (this.$route.path.includes('product-emission') || this.$route.path.includes('products')) ? TYPE_SELECTION[1] : TYPE_SELECTION[0];
  },
  computed: {
    ...mapState("userData", ["isInProductPage", 'planType']),
  },
  methods: {
    async updateTypeSelection(item) {
      this.showTypeSelectionPulldown = false;
      if (!item.isProductPage) {
        this.typeSelection = item;
        this.$router.push({ path: ROUTES.DASHBOARD }).catch(() => {
          location.reload()
        });
      } else {
        if (!this.planType.is_cfp) return;
        this.typeSelection = item;
        this.$router.push({ path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_DASHBOARD}` }).catch(() => {
          location.reload()
        });
      }
      let htmlElement = document.getElementsByTagName("html")[0];
      htmlElement.style.overflow = "auto";
    },
    handleCloseSwitchType() {
      this.showTypeSelectionPulldown = false;
    },
    isDisabledProduct(item) {
      if (!item.isProductPage) return false;
      return this.planType.is_cfp === false;
    }
  },
}
</script>
<style lang="scss" scoped>
.header-switch {
  background: $monoOffWhite;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  z-index: 100;
  margin-right: 20px;
  margin-top: 16px;
    position: relative;
  .type-selection {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $goldLight;
    border-radius: 4px;
    padding-right: 4px;
    overflow: hidden;
    &.focus-btn {
      outline: none;
      border: 2px solid $blueMid;
    }
    .btn-icon {
      display: block;
    }
    .btn-icon_active {
      display: none;
    }
    .type-selection-icon {
      width: 20px;
      height: 20px;
      margin: 0px 0px 0px 16px;
    }
    .type-selection-text {
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
      // font-family: 'Source Han Sans JP';
      padding: 9px 0 9px 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .type-selection-arrow-down {
      width: 10px;
      height: 10px;
      margin-right: 14px;
      //margin-left: calc(100% - 168px);
      &.deg-180 {
        transform: rotate(180deg);
      }
    }
    .btn-icon {
      display:block
    }
    .btn-icon_active {
      display: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $monoOffWhite;
      .btn-icon {
        display:none;
      }
      .btn-icon_active {
        display: block;
      }
    }
  }
  .type-selection-items {
    position: absolute;
    width: 100%;
    height: 80px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    z-index: 999;
    //width: fit-content;
    @media screen and (max-width: 768px) {
      left: 0;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 7px 13.5px 9px 16px;
      overflow: hidden;
      &:hover {
        background-color: $bgCusLight;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .type-selection-text {
        color: $monoBlack;
        margin: 0 4px 0 8px;
        font-size: 14px;
        width: 101px;
        height: 24px;
        display: flex;
        align-items: center;
        width: fit-content !important;
        white-space: nowrap;
      }
      &:first-child {
        border: 1px solid rgba(42, 42, 48, 0.1);
      }
      .checked-icon {
        width: 9px;
        height: 6px;
      }
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        background: $monoLight;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
      }
    }
  }
}
@include desktop {
  .header-switch {
    margin-left: 40px;
    right: unset;
    margin-top: unset;
    position: fixed;
    top: 8px;
    width: 141px;
    height: 28px;
    .type-selection {
      width: fit-content;
      padding-right: 9px;
      .type-selection-icon {
        width: 16px;
        height: 16px;
        margin: 0px 0px 0px 10px;
      }
      .type-selection-text {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.33px;
        margin: 0px 10px 0px 0;
        padding: 7px 0px 7px 6px;
      }
      .type-selection-arrow-down {
        margin: unset;
      }
    }
    .type-selection-items {
      width: 177px;
      top: 28px;
    }
  }
}
</style>
