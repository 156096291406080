const defaultVal = '0.00000000000000000000000'
const defaultValMore25 = '0.000000000000000000000000000000000000000000000000'
import {formatValue, $_helper_isNumberType} from '@/concerns/newRegisterData/wijmo.helper';
import store from '@/store';
export const addThoundSandComma = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    let numberOffCharactorAffterDot = afterDot[1].length
    for (let i = numberOffCharactorAffterDot; i > 2; i--) {
      if(afterDot[1][i - 1] !== '0') {
        break;
      }
      numberOffCharactorAffterDot -= 1 // minus last charactor if = 0
    }

    // if(indexNextZero === afterDot[1].length) {
    //   return `${afterDot[0]}.00`;
    // }

    let valueAffterDotFormat = afterDot[1].substring(0, numberOffCharactorAffterDot)
    if(valueAffterDotFormat === '0') {
      valueAffterDotFormat += '0'
    }

    return `${afterDot[0]}.${valueAffterDotFormat}`;
  }
  return afterDot[0];
}

export const addThoundSandCommaWithTwoDigit = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    const indexOffFirstZero = afterDot[1].indexOf("0")
    const isDontHasZeroInFirstTwoCharctor = afterDot[1].length > 2 && afterDot[1][0] !== '0' && afterDot[1][1] !== '0'

    if(indexOffFirstZero < 0 || isDontHasZeroInFirstTwoCharctor) {
      let valueAffterFormat = afterDot[1].substring(0, 2)
      return `${afterDot[0]}.${valueAffterFormat}`;
    } else if(afterDot[1][0] !== '0' && afterDot[1][1] === '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    } else if(afterDot[1][0] === '0' && afterDot[1][1] !== '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 3)}`;
    } else {
      let indexNextZero = 2
      for (let i = 2; i < afterDot[1].length; i++) {
        if(afterDot[1][i] !== '0') {
          break;
        }
        indexNextZero += 1
      }

      if(indexNextZero === afterDot[1].length) {
        return `${afterDot[0]}.00`;
      }

      let valueAffterDotFormat = afterDot[1].substring(0, indexNextZero + 2)
      if(valueAffterDotFormat === '0') {
        valueAffterDotFormat += '0'
      }

      return `${afterDot[0]}.${valueAffterDotFormat}`;
    }
    // return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
  }
  return afterDot[0];
}

export const addThoundSandCommaDashboard = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    const indexOffFirstZero = afterDot[1].indexOf("0")
    const isDontHasZeroInFirstTwoCharctor = afterDot[1].length > 2 && afterDot[1][0] !== '0' && afterDot[1][1] !== '0'

    if(indexOffFirstZero < 0 || isDontHasZeroInFirstTwoCharctor) {
      let valueAffterFormat = afterDot[1].substring(0, 2)
      return `${afterDot[0]}.${valueAffterFormat}`;
    } else if(afterDot[1][0] !== '0' && afterDot[1][1] === '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    } else if(afterDot[1][0] === '0' && afterDot[1][1] !== '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 3)}`;
    } else {
      let indexNextZero = 2
      for (let i = 2; i < afterDot[1].length; i++) {
        if(afterDot[1][i] !== '0') {
          break;
        }
        indexNextZero += 1
      }

      if(indexNextZero === afterDot[1].length) {
        return `${afterDot[0]}.00`;
      }

      let valueAffterDotFormat = afterDot[1].substring(0, indexNextZero + 2)
      if(valueAffterDotFormat === '0') {
        valueAffterDotFormat += '0'
      }

      return `${afterDot[0]}.${valueAffterDotFormat}`;
    }
    // return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
  }
  return afterDot[0];
}

export const roundingNumber = (num, decimalPlaces = 2) => {
  const result = Number(num).toFixed(decimalPlaces);
  return result;
}

export const listRangeYear = (startYear, endYear) => {
  const yearList = [];
  for (let year = endYear; year >= startYear; year--) {
    yearList.push(year);
  }

  return yearList;
}

export const formatNumberByConditions = (value, options = {}) => {
  let {
    thousandSeparator = ',', // Default "," for thousands
    decimalSeparator = '.', // Default "." for decimal
    decimalPlaces = 2, // Show up to 2 decimal
    isFormatValue = false, // Flag to add ".00" ID bug: 17580
    isAlowMore25digit = true, // 25-digit limit,
    formatUnit = '', // 'tCO2' || 'percent' || 'currency'
    type = '',
    isRealNumber = false, // Flag is get full decimal
    isRoundNumber = true,
    isGetMoreZeroInDecimalPart = false, // Flag keep last charactor if = 0
  } = options;
  thousandSeparator = store.getters['settingApp/getThousandSeparatorBySetting'] || ',';
  decimalSeparator = store.getters['settingApp/getDecimalSeparatorBySetting'] || '.';
  decimalPlaces = formatUnit ? store.getters['settingApp/getDecimalPlacesBySettingUnit'](formatUnit) : decimalPlaces;

  if (value && !value?.toString()?.includes('.') && isFormatValue) {
    value += `.${'0'.repeat(decimalPlaces)}`;
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0;
  }

  if (value === defaultVal || defaultValMore25 === value) {
    if (decimalPlaces === 0) return 0;
    return `0${decimalSeparator}${'0'.repeat(decimalPlaces)}`;
  }

  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if (isAlowMore25digit) {
    numberRange = 50;
  }

  let formattedValue = formatValue(value).toString().substring(0, numberRange);
  let [integerPart, decimalPart = ''] = formattedValue.split('.');

  // Format integer with thousandSeparator defined
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  // Handle isRealNumber 
  if (isRealNumber) {
    if (decimalPart) { // return full decimal partt

      // handle case decimals has more zeros after ex. 9.222000000
      let numberOffCharactorAffterDot = decimalPart.length
      for (let i = numberOffCharactorAffterDot; i > 2; i--) {
        if(decimalPart[i - 1] !== '0') {
          break;
        }
        if (!isGetMoreZeroInDecimalPart) numberOffCharactorAffterDot -= 1 // minus last charactor if = 0
      }
      let valueAffterDotFormat = decimalPart.substring(0, numberOffCharactorAffterDot)
      if (valueAffterDotFormat === '0') {
        valueAffterDotFormat += '0';
      }

      return `${integerPart}${decimalSeparator}${valueAffterDotFormat}`; // No truncation of decimals
    }

    return integerPart;
  }
  if (decimalPart) {
    decimalPart = formatValue(decimalPart);

    if (decimalPart.length < decimalPlaces) {
      decimalPart += '0'.repeat(decimalPlaces - decimalPart.length);
      return `${integerPart}${decimalSeparator}${decimalPart}`;
    }
    if (!isRoundNumber) {
      if (decimalPlaces === 0) return integerPart; // DecimalPlaces is 0
      return `${integerPart}${decimalSeparator}${decimalPart.substring(0, decimalPlaces)}`;
    }
    //Round numbers
    if (decimalPart.length > decimalPlaces) {
      let formattedValue = formatValue((value)).toString().substring(0, numberRange);
   
      let [integerPartRound, decimalPartRound] = formattedValue.split('.');

      let roundedDecimal = decimalPartRound.slice(0, decimalPlaces);
      let nextDigit = decimalPartRound.charAt(decimalPlaces);

      if (Number(nextDigit) >= 5) {
          // roundedDecimal = (BigInt(roundedDecimal) + BigInt(1)).toString() + roundedNumber(roundedDecimal);
          roundedDecimal = roundedNumber(roundedDecimal);
          if (roundedDecimal.length > decimalPlaces) {
            integerPartRound = (BigInt(integerPartRound) + BigInt(1)).toString();
            roundedDecimal = roundedDecimal.slice(1);
          }
      }
      decimalPartRound = roundedDecimal;
      integerPartRound = integerPartRound.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
      
      if (decimalPlaces === 0) return integerPartRound; // DecimalPlaces is 0
      return `${integerPartRound}${decimalSeparator}${decimalPartRound}`;
    }

    return `${integerPart}${decimalSeparator}${decimalPart.substring(0, decimalPlaces)}`;
  }
  if (decimalPlaces === 0 ) return integerPart;
  return `${integerPart}${decimalSeparator}${'0'.repeat(decimalPlaces)}`;
};

export const formatNumberRealNum = (num, options) => {
  return formatNumberByConditions(num, { isRealNumber: true, ...options });
}

const roundedNumber = (num) => {
  if(num.length === 1) {
    return (BigInt(num) + BigInt(1)).toString()
  }

  let [firstVal, secondVal] = [num[0], Number(num[1]) + 1];

  if (secondVal === 10) {
    return `${Number(firstVal) + 1}0`;
  }
  return `${firstVal}${secondVal}`;
};

export const formatNumberWithSeparators = (number) => {
  let [integerPart, decimalPart] = number.split(".");
  const thousandSeparator = store.getters['settingApp/getThousandSeparatorBySetting'] || ',';
  const decimalSeparator = store.getters['settingApp/getDecimalSeparatorBySetting'] || '.';
  integerPart = formatValue(integerPart).toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return decimalPart ? `${integerPart}${decimalSeparator}${decimalPart}` : integerPart;
}