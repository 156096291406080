import request from './base/request';
import store from '@/store';
import {listNumbers} from '@/constants/registerData';
import {batchReplacementViewCollection, emptyPromise} from '@/concerns/registerData/wijmo.helper';
import i18n from '@/lang/i18n';
export const getListServiceApi = () => {
  return request
    .get('/services')
    .then((res) => {
      const data = res.data;
      store.dispatch('registerData/updateDataServices', data, {root: true});
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const createServiceApi = (data) => {
  return request
    .post('/services', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListEnergyTypeApi = () => {
  return request
    .get('/energy-type')
    .then((res) => {
      const data = res.data;
      store.dispatch('registerData/updateDataEnergyType', data, {root: true});
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListData = (route) => {
  store.dispatch('registerData/actionUpdateIsLoading', true);
  return request
    .get(route)
    .then((res) => {
      // store.dispatch('registerData/updateDataList', res.data, { root: true });
      store.dispatch('registerData/actionUpdateTotalData', res.data.length, {root: true});
      store.dispatch('registerData/actionUpdateTotalTreatment', res?.total_treatment_methods || {}, {root: true});
      store.dispatch('registerData/actionUpdateIsLoading', false);
      return res;
    })
    .catch((err) => {
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw err;
    });
};

export const updateListData = (route, data, isRestoreData = false, isViewingEmission = false) => {
  store.dispatch('registerData/actionUpdateIsLoading', true);
  //convert to form
  const body = new FormData();
  let newData = {
    data: data,
  };
  if(data?.dataWorkflow) {
    newData = data
  }
  const payloadBlob = new Blob([JSON.stringify(newData)], {type: 'application/json'});
  body.append('data', payloadBlob);
  body.append('_method', 'put');
  if (isRestoreData) body.append('is_restoring_deleted', 1);
  if (isViewingEmission) body.append('is_viewing_emission', 1);
  return request
    .post(route, body)
    .then((res) => {
      store.dispatch('registerData/updateTotalDurationEmission', res.total_duration_emissions, {root: true});
      store.dispatch('registerData/actionUpdateTotalTreatment', res?.total_treatment_methods || {}, {root: true});
      store.dispatch('registerData/updateLatestRecord', res.latest_record);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      store.dispatch('registerData/updateTotalEmission', res.total_emissions, {root: true});
      if (isRestoreData) {
        store.dispatch('registerData/updateRecordInsert', data, {root: true});
        // store.dispatch('registerData/updateTotalAfterCreate', res.total_emissions, { root: true });
      }
      store.dispatch('actionsTable/updateActionName', '');
      store.dispatch('registerData/updateUndoRedoClick', '');
      return res;
    })
    .catch((err) => {
      store.dispatch('registerData/actionUpdateIsClearUndoStack', true);
      // store.dispatch('registerData/updateStatusIsUpdateData', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw err;
    });
};

export const updateDataToTheDatabase = (route, listItemEdited, isRestoreData = false) => {
  let listItemEditedValue = Object.values(listItemEdited).map(({ order_number, ...item }) => item);
  store.dispatch('registerData/actionUpdateIsLoading', true);
  const body = new FormData();
  let newData = {
    data: listItemEditedValue,
  };
  const payloadBlob = new Blob([JSON.stringify(newData)], {type: 'application/json'});
  body.append('data', payloadBlob);
  body.append('_method', 'put');
  if (isRestoreData) body.append('is_restoring_deleted', 1);
  return request
    .post(route, body)
    .then((response) => {
      store.dispatch('registerData/updateTotalDurationEmission', response.total_duration_emissions, {root: true});
      store.dispatch('registerData/actionUpdateTotalTreatment', response?.total_treatment_methods || {}, {
        root: true,
      });
      store.dispatch('registerData/updateLatestRecord', response.latest_record);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      store.dispatch('registerData/updateTotalEmission', response.total_emissions, {root: true});
      if (isRestoreData) {
        store.dispatch('registerData/updateRecordInsert', listItemEditedValue, {root: true});
      }
      store.dispatch('actionsTable/updateActionName', '');
      store.dispatch('registerData/updateUndoRedoClick', '');
      return response;
    })
    .catch((error) => {
      store.dispatch('registerData/actionUpdateIsClearUndoStack', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw error;
    });
};

export const updateTableRows = async (
  dataTable,
  patternUrl,
  listItemEdited,
  listItemEditedBlank,
  addedReplacement,
  isSyncing,
) => {
  let listItemEditedValue = Object.values(listItemEdited);
  let listItemEditedBlankValue = Object.values(listItemEditedBlank);
  let isSuccessEditBlank = false;
  try {
    const editedPromise = listItemEditedValue.length
      ? await updateDataToTheDatabase(patternUrl, listItemEdited, false)
      : await emptyPromise();
    let _ = await editedPromise;
    const editedBlankPromise = listItemEditedBlankValue.length ? await addDataToTheDatabase(patternUrl, listItemEditedBlankValue) : await emptyPromise();
    let editedBlankResponse = await editedBlankPromise;
    if (Object.keys(listItemEditedBlank).length) {
      let itemEdited = dataTable.itemsEdited.filter((item) => item.isSyncing === isSyncing);
      batchReplacementViewCollection(
        itemEdited,
        editedBlankResponse.record_insert,
        Object.keys(listItemEditedBlank),
        addedReplacement,
      );
    }
    dataTable.itemsEdited.forEach((item) => (item.isSyncing = false));
    isSuccessEditBlank = true;
    await store.dispatch('commonApp/actionUpdateIsSuccess', true, {root: true});
    if (!isSuccessEditBlank) {
      await store.dispatch('commonApp/updateStatusAction', false, {root: true});
    }
    dataTable.itemsEdited.length = 0;
  } catch (error) {
    dataTable.itemsEdited.forEach((item) => (item.isSyncing = false));
    dataTable.itemsEdited.length = 0;
  }
  return isSuccessEditBlank;
};

const formatNumber = (input) => {
  if (!input) {
    return;
  }

  return input.toString().split(',').join('');
};

export const addDataToTheDatabase = (route, listItems) => {
  let listItemsValue = Object.values(listItems);
  listItemsValue.forEach((item, itemIndex) => {
    Object.keys(item).forEach((key) => {
      if (listNumbers.includes(key)) {
        listItems[itemIndex][key] = Number(formatNumber(listItems[itemIndex][key]));
      }
      if (!listItems[itemIndex].emissions) {
        listItems[itemIndex].emissions = 0;
      } else {
        listItems[itemIndex].emissions = Number(formatNumber(listItems[itemIndex].emissions));
      }
    });
  });
  // store.dispatch('registerData/actionUpdateTotalData', store.state.registerData.totalData + listItemsValue.length, {
  //   root: true,
  // });
  // store.dispatch('registerData/actionAllowAddNew', false);
  store.dispatch('registerData/actionUpdateIsLoading', true);
  const body = new FormData();
  let newData = {
    data: listItemsValue,
  };
  const payloadBlob = new Blob([JSON.stringify(newData)], {type: 'application/json'});
  body.append('data', payloadBlob);
  return request
    .post(route, body)
    .then((response) => {
      store.dispatch('registerData/updateTotalAfterCreate', response.total_record_insert, {root: true});
      // store.dispatch('registerData/updateRecordInsert', res.record_insert, { root: true });
      store.dispatch('registerData/actionAddTotalTreatment', response?.total_treatment_insert || {}, {root: true});
      store.dispatch('registerData/updateLatestRecord', response.latest_record);
      store.dispatch('actionsTable/updateActionName', '');
      store.dispatch('registerData/updateUndoRedoClick', '');
      store.dispatch('registerData/actionAllowAddNew', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);

      return response;
    })
    .catch((error) => {
      store.dispatch('registerData/actionUpdateIsClearUndoStack', true);
      store.dispatch('registerData/actionAllowAddNew', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw error;
    });
}

export const addListData = (route, data) => {
  data.forEach((row, i) => {
    Object.keys(row).forEach((key) => {
      if (listNumbers.includes(key)) {
        data[i][key] = Number(formatNumber(data[i][key]));
      }
      if (!data[i].emissions) {
        data[i].emissions = 0;
      } else {
        data[i].emissions = Number(formatNumber(data[i].emissions));
      }
    });
  });
  // add new data
  // store.dispatch('registerData/actionUpdateTotalData', store.state.registerData.totalData + data.length, {
  //   root: true,
  // });
  // store.dispatch('registerData/actionAllowAddNew', false);
  store.dispatch('registerData/actionUpdateIsLoading', true);
  //convert to form
  const body = new FormData();
  let newData = {
    data: data,
  };
  const payloadBlob = new Blob([JSON.stringify(newData)], {type: 'application/json'});
  body.append('data', payloadBlob);
  return request
    .post(route, body)
    .then((res) => {
      store.dispatch('registerData/updateTotalAfterCreate', res.total_record_insert, {root: true});
      // store.dispatch('registerData/updateRecordInsert', res.record_insert, { root: true });
      store.dispatch('registerData/actionAddTotalTreatment', res?.total_treatment_insert || {}, {root: true});
      store.dispatch('registerData/updateLatestRecord', res.latest_record);
      store.dispatch('actionsTable/updateActionName', '');
      store.dispatch('registerData/updateUndoRedoClick', '');
      store.dispatch('registerData/actionAllowAddNew', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);

      return res;
    })
    .catch((err) => {
      console.log('error: ', data);
      store.dispatch('registerData/actionUpdateIsClearUndoStack', true);
      store.dispatch('registerData/actionAllowAddNew', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw err;
    });
};

export const deleteListData = (route, isUpdateTreatment = false) => {
  let listId = [];
  let params = route.split('?')[1].split('&');
  params.forEach((item) => {
    const id = Number(item.split('=')[1]);
    if (id) {
      listId.push(id);
    }
  });

  if (!listId.length) {
    return;
  }

  const body = new FormData();
  let formData = {
    id: listId,
  };
  const payloadBlob = new Blob([JSON.stringify(formData)], {type: 'application/json'});
  body.append('data', payloadBlob);
  body.append('_method', 'delete');
  store.dispatch('registerData/actionUpdateIsLoading', true);
  return request
    .post(`${route.split('?')[0]}`, body)
    .then((res) => {
      store.dispatch('registerData/updateTotalAffterDelete', res.total_record_insert, {
        root: true,
      });
      if (isUpdateTreatment) {
        store.dispatch('registerData/actionUpdateTotalTreatmentAffterDelete', res.total_treatment_insert, {
          root: true,
        });
      }
      store.dispatch('actionsTable/updateActionName', '');
      store.dispatch('registerData/updateUndoRedoClick', '');
      store.dispatch('registerData/actionUpdateStatusDelete', {status: true, ids: listId}, {root: true});
      store.dispatch('registerData/updateLatestRecord', res.latest_record);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      return res;
    })
    .catch((err) => {
      store.dispatch('registerData/actionUpdateIsClearUndoStack', true);
      store.dispatch('registerData/actionUpdateIsLoading', false);
      throw err;
    });
};

export const getListBusinessNameApi = () => {
  return request
    .get(`/branch/list-business-name?contractor_id=${store.state.userData.contractor}`)
    .then((res) => {
      const data = [];
      res.data.forEach((item) => {
        if (item) {
          let business = {
            key: item,
            value: item,
          };
          data.push(business);
        }
      });
      store.dispatch('registerData/updateListBusinessName', data, {root: true});
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListCompanyNameApi = () => {
  return request
    .get(`/branch/list-company-name?contractor_id=${store.state.userData.contractor}&status=1`)
    .then((res) => {
      const data = [];
      res.data.forEach((item, i) => {
        let company = {
          key: i,
          value: item,
        };
        data.push(company);
      });
      store.dispatch('registerData/updateListCompanyName', data, {root: true});
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListTypeApi = () => {
  return request
    .get(`/branch/list-type`)
    .then((res) => {
      const data = [];
      res.data.forEach((item) => {
        let type = {
          key: item.id,
          value: item.type,
        };
        data.push(type);
      });
      store.dispatch('registerData/updateListType', data, {root: true});
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListDbCustomize = (params) => {
  store.dispatch('registerData/updateListDbCustomize', [], {root: true});
  return request
    .get(`/db-customize`, {params: params})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListMasterDb = (params) => {
  store.dispatch('registerData/updateListDbCustomize', [], {root: true});
  return request
    .get(`/db-master`, {params: params})
    .then((res) => {
      let masterDb = [];
      res.data.map((item) => {
        masterDb.push({
          ...item,
          value_source: formatValueSourceNumber(item.value_source),
          source: item.source.trim(),
          item_name: item.item_name ? item.item_name.trim() : item.source,
          idType: `${item.id}-${item.type}`
        });
      });
      // store.dispatch('registerData/updateListDbCustomize', masterDb, {root: true});
      let idList = [];
      res.data.forEach((item) => {
        idList.push(item.id);
      });
      // store.dispatch('registerData/updateListIdDbCustomize', idList, {root: true});

      return { masterDb, idList};
    })
    .catch((err) => {
      throw err;
    });
};

export const getStatusInput = (params) => {
  return request
    .get(`/category-input-status`, {params: params})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateStatusInput = (data) => {
  return request
    .put(`/category-input-status`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListDetailBranch = () => {
  return request
    .get(`/dashboard/list-branch?contractor_id=${store.state.userData.contractor}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

function formatValueSourceNumber(number) {
  const subRange = number?.toString()?.includes('-') ? 26 : 25
  return number?.toString()?.slice(0, subRange)
}

function formatValueSourceNumber1(number) {
  let formatNumber = '';
  if (number.includes('.')) {
    const natural = number.split('.')[0].length;
    const decimals = number.split('.')[1].length;
    if (natural + decimals + 1 <= 20) {
      return number;
    }
    formatNumber = parseFloat(number).toFixed(20 - natural - 1);
    return formatNumber;
  }
  return number;
}

export const getListWorkflowApi = (params) => {
  return request
    .get('/workflow-data', { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getListLogHistory = (params) => {
  return request
    .get('/workflow-data-log', { params: params})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getListCommentHistory = (params) => {
  return request
    .get('/workflow-data-comment', { params: params})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


function moveElementToLastPosition(arr) {
  const fromIndex = arr.findIndex((item) => item.key === i18n.t("register_data.checkbox_blank"))
  if (fromIndex >= 0) {
    const arrLength = arr.length
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(arrLength - 1, 0, element);
  }
  return arr
}

const checkOrganizationalDivisionDisabled = (organizationalList, filterPatternList) => {
  let compareOrganizationalList = [];
  organizationalList.forEach(organiDiv => {
    const isDisabled = filterPatternList.filter(item => item.organizational_division === organiDiv.key).every(item => item.status === false);
    compareOrganizationalList.push({
      key: organiDiv.key,
      value: organiDiv.value,
      status: isDisabled ? false : true,
      branch_ids: organiDiv.branch_ids,
    })
  })
  return compareOrganizationalList;
}

export const getSummaryScore = (params) => {
  return request
    .get('/summary-score', { params: params})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const getMonthExistDataSummaryScore = () => {
  return request
    .get('/summary-score/month-exist-data')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const getViewingEmissionList = (payload) => {
  return request
    .get('/view-emission-list', {params: payload})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    })
};

export const updateUserEncoding = (params) => {
  return request
    .put('/update-encoding', params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    })
}