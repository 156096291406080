import axios from 'axios';
import request from '@/api/base/request';

const prepareCancelRequest = (requests, key) => {
  const cancelTokenSource = axios.CancelToken.source();

  return {
    cancelTokenSource: cancelTokenSource,
    config: {
      cancelToken: cancelTokenSource.token,
    },
    requests: {
      ...requests,
      [`${key}`]: cancelTokenSource,
    },
  };
};

const prepareFormData = (params, requestType = 'get') => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', requestType);

  return body;
};

const handleActionCancelRequest = (requests, key) => {
  if (requests[key]) {
    requests[key].cancel(`Cancel previous request ${key}`);
    requests = {
      ...requests,
      [`${key}`]: null,
    };
  }

  return requests;
};

const handleFinallyCancelRequest = (requests, key, cancelTokenSource) => {
  if (requests[key] === cancelTokenSource) {
    requests = {
      ...requests,
      [`${key}`]: null,
    };
  }
};

const prepareApiData = (params) => {
  let { payload, url, key, requests } = params;

  const data = prepareCancelRequest(requests, key);
  const { cancelTokenSource, config } = data;

  handleActionCancelRequest(requests, key);
  requests = data.requests;

  const body = prepareFormData(payload);
  return request
    .post(url, body, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      handleFinallyCancelRequest(requests, key, cancelTokenSource);
    });
};

export { handleActionCancelRequest, prepareCancelRequest, prepareFormData, handleFinallyCancelRequest, prepareApiData };
