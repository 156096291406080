import request, { getFileName } from './base/request';

import {
  prepareCancelRequest,
  prepareFormData,
  handleActionCancelRequest,
  handleFinallyCancelRequest,
} from '@/api/base/prepareRequest';
const NEW_REGISTER_DATA_SERVICE_URL = 'product/product-workflows';
let requests = {};

export const actionProductWorkflow = (data, method = 'post') => {
  return request[method](`/product/product-workflows`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateProductApproved = (productId, data) => {
  return request
    .put(`/product/product-data/${productId}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

// Get Product code by product name: CFP Updated 4/9/2024
export const getProductInfoByParams = (params) => {
  const queryString = new URLSearchParams(params).toString();
  return request
    .get(`/cfp-product/get-code-by-name?${queryString}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const getProductDetail = (id) => {
  return request
    .get(`/product/product-workflows/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const getProductsListWorkflowApi = (params) => {
  return request
    .get('/product/product-workflows', { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUnitListApi = (params) => {
  return request
    .get('/product/units', { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCategoryApi = () => {
  return request
    .get('/cfp-category/cfp-product')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCurencyListApi = (params) => {
  return request
    .get('/product/currencies', { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getProductMasterApi = () => {
  return request
    .get('/product/product-master')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchEmissions = (payload) => {
  return request
    .get('/product/search-emission', { params: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getProductListLogHistory = (params) => {
  return request
    .get('product/log', { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};
export const getProductListCommentHistory = (params) => {
  return request
    .get('product/comment', { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};
export const deleteProductData = (id) => {
  return request
    .delete(`/product/product-workflows/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};
export const getProductsDashboardApi = (params) => {
  return prepareApiData({
    payload: params,
    url: `/product/dashboard`,
    key: 'getProductsDashboardApi',
  });
};
export const getListMostEmissionDashboardData = (params) => {
  return prepareApiData({
    payload: params,
    url: `product/dashboard/list-most-emission`,
    key: 'getListMostEmissionDashboardData',
  });
};

export const getDataEmissionsForBubbleChart = (params) => {
  return prepareApiData({
    payload: params,
    url: `product/dashboard/ghg-per-product`,
    key: 'getDataEmissionsForBubbleChart',
  });
};

export const getListProductPermissionApi = (params) => {
  return request
    .get('/cfp-product-permission', { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProductPermissionApi = (params) => {
  return request
    .put('/cfp-product-permission', params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getProductNotification = () => {
  return request
    .get('product/notification')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const updateProductNotification = (params) => {
  return request
    .put('product/notification', { ...params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};
async function updateDataProductWorkFollowApi(payload) {
  return await request.put(NEW_REGISTER_DATA_SERVICE_URL, payload);
}
async function addDataProductWorkWorkFollowApi(payload) {
  return await request.post(NEW_REGISTER_DATA_SERVICE_URL, preparePayload(payload));
}
export const getProductData = () => {
  return request
    .get('product/product-data')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const getExportReport = (data, isCsv = false) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');
  let url = `product/export-report`;
  if (isCsv) {
    url = `product/export-report/csv`;
  }
  return request
    .post(url, body, { responseType: 'blob' })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

const prepareApiData = (params) => {
  const { payload, url, key } = params;

  const data = prepareCancelRequest(requests, key);
  const { cancelTokenSource, config } = data;

  handleActionCancelRequest(requests, key);
  requests = data.requests;

  const body = prepareFormData(payload);
  return request
    .post(url, body, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      handleFinallyCancelRequest(requests, key, cancelTokenSource);
    });
};
export const getExportReportProduct = (data, isCsv = false) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');
  let url = `/export-report-cfp-product`;
  if (isCsv) {
    url = `/export-report-cfp-product/csv`;
  }
  return request
    .post(url, body, { responseType: 'blob' })
    .then((res) => {
      const currentFileName = getFileName();
      return { data: res, fileName: currentFileName };
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

export const getListProductApproved = () => {
  return request
    .get('/cfp-product/approved')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getListProductApprovedV2 = () => {
  return request
    .get('/cfp-product/v2/approved')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export default {
  updateDataProductWorkFollowApi,
  addDataProductWorkWorkFollowApi,
};
