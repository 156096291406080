import request from './base/request';

const BASIC_UNIT = '/basic-unit';

const getListBasicUnit = (params) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');
  return request
    .post(BASIC_UNIT, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getDbListFromCachedId = (id) => {
  return request
    .get(`/basic-unit/${id}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getListUniqueId = (params) => {
  return request
    .get(`/basic-unit/only-key`, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getListUniqueItemName = (params) => {
  return request
    .get(`/basic-unit/only-name-basic`, { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getLatestSync = () => {
  // TODO: update API get last updated.
  return request
    .get(`/basic-unit/last-updated`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export { getListBasicUnit, getDbListFromCachedId, getListUniqueId, getListUniqueItemName, getLatestSync };
