<template>
  <div>
    <footer-layout
      :spList="spList"
      :toolsList="toolsList"
      :settingList="settingList"
      :otherList="otherList"
      @goToHome="goToHome"
      @logout="logout"
    ></footer-layout>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import { ROLE } from '@/constants/role';
import FooterLayout from '@/components/footer/index.vue';
export default {
  components: { FooterLayout },
  computed: {
    ...mapState('userData', ['currentUser']),
    isAdminRole() {
      return ROLE.ADMIN === this.currentUser?.user?.role_id;
    },
    isUserRole() {
      return ROLE.USER === this.currentUser?.user?.role_id;
    },
    spList() {
      return [
        {
          href: 'https://sustech-inc.co.jp/carbonix/',
          label: this.$t('footer.hyperlink_carbonix_official_site'),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t('footer.hyperlink_faqs'),
        },
        {
          href: 'https://sustech-inc.co.jp/',
          label: this.$t('footer.hyperlink_operating_company'),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t('footer.hyperlink_term_of_use'),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t('footer.hyperlink_privacy_policy'),
        },
        {
          href: 'https://www.truste.or.jp/hssl/validate/01632.php',
          label: this.$t('footer.hyperlink_truste_certified_page'),
        },
      ];
    },
    toolsList() {
      return [
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_DASHBOARD}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_DASHBOARD}`,
          label: this.$t('left_menu.button_dashboard'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          label: this.$t('cfp_emissions_product.title_list_products'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.REGISTER_PRODUCT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.REGISTER_PRODUCT}`,
          label: this.$t('left_menu.button_registration_product'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
          label: this.$t('left_menu.button_application_status'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
          label: this.$t('footer.hyperlink_approve'),
          enabled: !this.isUserRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_PRIMARY_DATA_MERGE}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_PRIMARY_DATA_MERGE}`,
          label: this.$t('left_menu.button_primary_data_merge'),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          label: this.$t('footer.hyperlink_offset_transaction'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.EXPORT_REPORT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.EXPORT_REPORT}`,
          label: this.$t('left_menu.button_export_report'),
          enabled: true,
        },
      ].filter((item) => item.enabled === true);
    },
    settingList() {
      return [
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
          label: this.$t('footer.hyperlink_setting_top'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.USER_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.USER_MANAGEMENT}`,
          label: this.$t('footer.hyperlink_user_management'),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.FACILITY_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.FACILITY_MANAGEMENT}`,
          label: this.$t('footer.hyperlink_facility_management'),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.VIEWER_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.VIEWER_MANAGEMENT}`,
          label: this.$t('footer.hyperlink_view_authority_management'),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CALCULATION_TERM}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CALCULATION_TERM}`,
          label: this.$t('footer.hyperlink_year_setting'),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          label: this.$t('footer.hyperlink_customized_db_management'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_AUTO_APPROVAL}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_AUTO_APPROVAL}`,
          label: this.$t('setting.button_setting_auto_approval'),
          enabled: !this.isUserRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_LANGUAGE}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_LANGUAGE}`,
          label: this.$t('setting.button_setting_language'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CHANGE_PASSWORD_USER}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CHANGE_PASSWORD_USER}`,
          label: this.$t('footer.hyperlink_change_password'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CSV_FORMAT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CSV_FORMAT}`,
          label: this.$t('footer.hyperlink_csv_format'),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_DISPLAY_FORMAT_NUMBER}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_DISPLAY_FORMAT_NUMBER}`,
          label: this.$t('setting_format_number.button_setting_format_number_on_setting'),
          enabled: true,
        },
      ].filter((item) => item.enabled === true);
    },
    otherList() {
      return [
        {
          href: 'https://sustech-inc.co.jp/carbonix/',
          label: this.$t('footer.hyperlink_carbonix_official_site'),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t('footer.hyperlink_faqs'),
        },
        {
          href: 'https://sustech-inc.co.jp/',
          label: this.$t('footer.hyperlink_operating_company'),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t('footer.hyperlink_term_of_use'),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t('footer.hyperlink_privacy_policy'),
        },
        {
          href: 'https://www.truste.or.jp/hssl/validate/01632.php',
          label: this.$t('footer.hyperlink_truste_certified_page'),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.VERSIONS}`,
          label: this.$t('footer.hyperlink_versions'),
        },
      ];
    },
  },
  methods: {
    goToHome() {
      this.$router.push({ path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}` });
    },
    logout() {
      this.$emit('logout');
    },
  },
};
</script>
