import i18n from '@/lang/i18n.js';

export const CURRENCY = {
  1: '円',
  2: 'ARS',
  3: 'AUD',
  4: 'ATS',
  5: 'BEF',
  6: 'BRL',
  7: 'CAD',
  8: 'CLP',
  9: 'CNY',
  10: 'DKK',
  11: 'EGP',
  12: 'XEU',
  13: 'FIM',
  14: 'FRF',
  15: 'DEM',
  16: 'GRD',
  17: 'HKD',
  18: 'INR',
  19: 'IDR',
  20: 'IEP',
  21: 'ILS',
  22: 'ITL',
  23: 'KRW',
  24: 'LUF',
  25: 'MYR',
  26: 'MXP',
  27: 'NLG',
  28: 'NZD',
  29: 'NOK',
  30: 'PHP',
  31: 'PLN',
  32: 'PTE',
  33: 'RUR',
  34: 'SGD',
  35: 'ZAR',
  36: 'ESP',
  37: 'SEK',
  38: 'CHF',
  39: 'TWD',
  40: 'THB',
  41: 'TRL',
  42: 'GBP',
  43: 'USD',
};

export const UNIT = {
  1: '個',
  2: '枚',
  3: '台',
  4: '本',
  5: 'セット',
  6: '式',
  7: '袋',
  8: '巻',
  9: '缶',
  10: '箱',
  11: '品',
  12: '製品',
  13: '部品',
  14: 'ダース',
  15: '丁',
  16: 'm',
  17: 'g',
  18: '㎡',
  19: 'ha',
  20: '㎥',
  21: 'l',
  22: '寸',
  23: '尺',
  24: '間',
  25: '坪',
  26: '升',
  27: 'yd',
  28: 'in',
  29: 'ft',
  30: 'ac',
  31: 'gal',
  32: 'pd',
  33: 'bbl',
  34: 'ct',
  35: 'mm',
  36: 'mg',
  37: 'm㎡',
  38: 'm㎥',
  39: 'ml',
  40: 'km',
  41: 'kg',
  42: 'k㎡',
};

const CREATE_NEW = i18n.t('b_register_product.radio_select_new_registered_product');
const CREATE_FROM_PRODUCT = i18n.t('b_register_product.radio_select_select_from_registered_products');
export const PRODUCT_LIST = [
  {
    name: '製品A',
    code: 'ABCDEF-GH',
  },
  {
    name: '製品B',
    code: 'ABCDEF-GB',
  },
];
export const PRODUCT_INPUT_TYPE = {
  CREATE_NEW: CREATE_NEW,
  CREATE_FROM_PRODUCT: CREATE_FROM_PRODUCT,
};
export const PRODUCT_DASHBOARD = {
  GET_ALL_PRODUCT: i18n.t('product_dashboard_main.radio_select_all_products'),
  GET_SPECIFICS_PRODUCT: i18n.t('product_dashboard_main.radio_select_specify_product'),
};
export const PALETTE_COLOR = ['#89B473', '#327998', '#0072A3', '#BF293D', '#C24E4B', '#E0A329'];
export const TYPE_SELECTION = [
  {
    name: 'header.pulldown_select_organizational_unit',
    icon: 'organizational-header.svg',
    isProductPage: false,
  },
  {
    name: 'header.pulldown_select_product_unit',
    icon: 'product-header.svg',
    isProductPage: true,
  },
];
export const PALETTE_COLOR_REDUCTION = [
  'rgba(0, 143, 204, 1)',
  'rgba(0, 143, 204, 1)',
  'rgba(228, 91, 88, 1)',
  'rgba(128, 188, 98, 1)',
  'rgba(224, 163, 41, 1)',
  'rgba(159, 99, 189, 1)',
  'rgba(172, 174, 78, 1)',
];
export const ICP_TEXT = i18n.t('icp_target_integration.title_reduction_target_offset');
export const FAKE_VALUE_ZERO = 0.0000000001;
export const ICP_BOTTOM_TYPE = {
  1: {
    id: 1,
    className: 'actual-present',
    label: i18n.t('icp_target_integration.title_bottom_icp_actual_value'),
  },
  2: {
    id: 2,
    className: 'actual-future',
    label: i18n.t('icp_target_integration.title_bottom_icp_actual_value'),
  },
  3: {
    id: 3,
    className: 'no-achieve-present',
    label: i18n.t('icp_target_integration.title_bottom_icp_un_achieved_value'),
  },
  4: {
    id: 4,
    className: 'no-achieve-future',
    label: i18n.t('icp_target_integration.title_bottom_icp_amount_un_achieved_value'),
  },
};
export const FILL_COLOR_ICP = '#FFF';

export const DECLARED_UNIT_LIST = [
  {
    key: 'L',
    value: 'L',
  },
  {
    key: 'kg',
    value: 'kg',
  },
  {
    key: '㎥',
    value: '㎥',
  },
  {
    key: 'kWh',
    value: 'kWh',
  },
  {
    key: 'MJ',
    value: 'MJ',
  },
  {
    key: 'tkm',
    value: 'tkm',
  },
  {
    key: '㎡',
    value: '㎡',
  },
];
export const BG_SCOPE = {
  1: '#327998',
  2: '#C24E4B',
  3: '#89B473',
  4: '#7C898C',
};

// IdNode random, chỉ cần ko trùng nhau
export const DEFAULT_BOUNDARY = [
  {
    name: i18n.t('product_boundary.default_boundary_raw_material_procurement'),
    processes: [
      {
        name: i18n.t('product_boundary.default_boundary_raw_material_procurement_process_1'),
        cut_off: false,
        isOpen: true,
        children: [],
        idNode: 4,
        quantity: 1,
        // isApproveProduct: true
      },
    ],
    isOpen: true,
    quantity: 1,
    idNode: 14
  },
  {
    name: i18n.t('product_boundary.default_boundary_production'),
    processes: [
      {
        idNode: 1,
        name: i18n.t('product_boundary.default_boundary_production_process_1'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 2,
        name: i18n.t('product_boundary.default_boundary_production_process_2'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 3,
        name: i18n.t('product_boundary.default_boundary_production_process_3'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
    ],
    isOpen: true,
    quantity: 1,
    idNode: 15
  },
  {
    name: i18n.t('product_boundary.default_boundary_distribution_and_sales'),
    processes: [
      {
        idNode: 5,
        name: i18n.t('product_boundary.default_boundary_distribution_and_sales_process_1'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 6,
        name: i18n.t('product_boundary.default_boundary_distribution_and_sales_process_2'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 7,
        name: i18n.t('product_boundary.default_boundary_distribution_and_sales_process_3'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,

      },
      {
        idNode: 8,
        name: i18n.t('product_boundary.default_boundary_distribution_and_sales_process_4'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 9,
        name: i18n.t('product_boundary.default_boundary_distribution_and_sales_process_5'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
    ],
    isOpen: true,
    quantity: 1,
    idNode: 16
  },
  {
    name: i18n.t('product_boundary.default_boundary_use_and_maintenance'),
    processes: [
      {
        idNode: 10,
        name: i18n.t('product_boundary.default_boundary_use_and_maintenance_process_1'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 11,
        name: i18n.t('product_boundary.default_boundary_use_and_maintenance_process_2'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
    ],
    isOpen: true,
    quantity: 1,
    idNode: 17
  },
  {
    name: i18n.t('product_boundary.default_boundary_disposal_and_recycling'),
    processes: [
      {
        idNode: 12,
        name: i18n.t('product_boundary.default_boundary_disposal_and_recycling_process_1'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
      {
        idNode: 13,
        name: i18n.t('product_boundary.default_boundary_disposal_and_recycling_process_2'),
        cut_off: false,
        children: [],
        isOpen: true,
        quantity: 1,
      },
    ],
    isOpen: true,
    quantity: 1,
    idNode: 18
  },
];

export const MAX_LAYER = 10;

export const SESSIONS_MESSAGE_ERROR = 'product-emissions-error';

export const TABLE_STATUS = {
  OLD_TABLE: 1,
  SUPPLIER_TABLE: 2,
  BOTH: 3,
};
