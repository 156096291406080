<template>
  <div id="idApp">
    <router-view />
    <div v-for="(popup, index) in popups" :key="index">
      <notification-popup
        :dialog="true"
        :message="popup.message"
        @submit="closePopup(index)"
        :confirmText="$t('register_data.button_close')"
      />
    </div>
  </div>
</template>

<script>
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getNotiPopUpWhenImportCsvSuccess } from '@/api/auth';
import { ROUTES } from '@/router/constants';
import webStorage from '@/utils/webStorage';
import { LAST_UPDATED_AT } from '@/constants/configs';
import { getLatestSync, getListBasicUnit } from '@/api/basic-unit';
import { makeNewId, prepareMasterOrCustomizeDB, prepareMasterGhg } from '@/concerns/utils/master-or-customize-db';
import { insertDataBasicUnit, insertDataBasicUnitItemNameNew } from '@/utils/indexed-db/basicUnit'
import { getListMasterDb } from '@/api/registerData';
import { mapState } from 'vuex';
import router from '@/router';
export default {
  name: 'App',
  data() {
    return {
      dialogNotiImportSuccess: false,
      messageDialogImportSuccess: '',
      checkInterval: null, // ID của setInterval
      popups: [],
      intervalSyncBasicUnit: null,
      basicUnitSyncing: false,
    };
  },
  computed: {
    ...mapState('userData', ['currentUser']),
  },
  components: {
    NotificationPopup,
  },
  methods: {
    // Kiểm tra trạng thái trong localStorage
    isImportingGroupsActive() {
      return localStorage.getItem('hasGroupsImporting') === 'true' && !this.isAuthChildRoute();
    },
    isAuthChildRoute() {
      const authPath = ROUTES.AUTH;
      return this.$route.path.startsWith(authPath);
    },

    closePopup(index) {
      this.popups.splice(index, 1);
      // reload component list all to get data
      if (this.popups.length === 0) {
        const channel = new BroadcastChannel('reloadListAllAfterImportSuccess');
        channel.postMessage({ type: 'reloadListAllAfterImportSuccessEvent' });
      }
    },

    // Gọi API để lấy thông báo
    async fetchMessages() {
      try {
        const response = await getNotiPopUpWhenImportCsvSuccess();
        if (response.data?.length) {
          this.popups = response.data.map((item) => ({
            message: item.message || '',
          }));
          localStorage.setItem('hasGroupsImporting', 'false'); // Reset trạng thái
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },

    // Theo dõi định kỳ trạng thái localStorage
    startCheckingStorage() {
      this.checkInterval = setInterval(() => {
        if (this.isImportingGroupsActive()) {
          this.fetchMessages();
        }
      }, 5000);
    },
    syncBasicUit: async () => {
      console.log('Sync DB unit: starting...');
      try {
        //todo: update list master
        const payload = {
          db_types: [3],
          scope_id: 3,
          category_id: 15,
          method_id: 0,
          status: true,
        }
        const response = await getListBasicUnit();
        const dbList = await getListMasterDb(payload)

        const customDataMaster = dbList.masterDb.map(item => {
          return {
            ...item,
            type: item.type,
            origin_id: item.id,
            source: item?.item_name,
            sourceName: item.item_name,
            itemNameNew: makeNewId(item.item_name, 1),
            name_basic: item.item_name
          }
        })

        const basicUnitData = {
          source: {},
        };

        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          if (!basicUnitData.source[item.name_basic]) {
            basicUnitData.source[item.name_basic] = {
              ...item,
              type: item.type_db,
              origin_id: item.id,
              source: item?.name_basic,
              origin_source: item?.source,
              sourceName: item.source,
            };
          }
        }
        const customData = response.map(item => {
          return {
            ...item,
            type: item.type_db,
            origin_id: item.id,
            source: item?.name_basic,
            sourceName: item.source,
          }
        })
        console.log('Sync DB unit: Writing to Indexed DB');

        console.time('Write DB unit to Indexed DB');
        const prepareGhgDbItem = prepareMasterGhg(customDataMaster)
        const data = prepareMasterOrCustomizeDB([...Object.values(basicUnitData.source)]);
        const customDataId = prepareMasterOrCustomizeDB([...customData]);

        Promise.all([insertDataBasicUnit([...customDataId, ...prepareGhgDbItem]), insertDataBasicUnitItemNameNew([...data, ...prepareGhgDbItem], 'itemNameNew')]).then(() =>
          console.timeEnd('Write DB unit to Indexed DB'),
        );
      } catch (error) {
        console.error('Sync DB unit: Failed', error);
      }
      console.log('Sync DB unit: Ended.');
    },
  },
  mounted() {
    this.intervalSyncBasicUnit = setInterval(async () => {
      const token = webStorage.getToken();

      // break if unauthorize.
      if (!token || this.basicUnitSyncing) {
        return;
      }
      if (router.currentRoute.path.includes('supplier')) {
        return;
      }
      // check need re-sync db unit.
      const lastUpdatedAt = webStorage.get(LAST_UPDATED_AT);
      const serverLatestSyncAt = await getLatestSync();

      if (serverLatestSyncAt.last_updated !== lastUpdatedAt) {
        this.basicUnitSyncing = true;
        await this.syncBasicUit();
        this.basicUnitSyncing = false;
        webStorage.set(LAST_UPDATED_AT, serverLatestSyncAt.last_updated);
        return;
      }
    }, 10000); // 10s     

    if (document.querySelector('[data-app]') === null) {
      const appElement = document.createElement('div');
      appElement.setAttribute('data-app', '');
      document.body.appendChild(appElement);
    }

    if (this.isImportingGroupsActive()) {
      this.fetchMessages();
    }

    this.startCheckingStorage();
  },
  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>
