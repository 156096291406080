import { insert, createIndexedDB, findOne, truncate, getAll, findMany, hasData, dropDatabase } from '@/concerns/index-db'

const DB_UNIT_DEFAULT = 'db_unit_default'
const DB_UNIT_BY_ITEM_NAME_NEW = 'db_unit_item_name_new'

const getNameDbUnitDefault = () => {
  return localStorage.getItem(DB_UNIT_DEFAULT) || DB_UNIT_DEFAULT
}
const setNameDbUnitDefault = (dbName) => {
  return localStorage.setItem(DB_UNIT_DEFAULT, dbName)
}

// Default
export const insertDataBasicUnit = async (data) => {
  const oldDBName = getNameDbUnitDefault()
  const newDBName = `${DB_UNIT_DEFAULT}_${new Date().getTime()}`

  await createIndexedDB(newDBName, newDBName)

  await insert(newDBName, newDBName, data)

  setNameDbUnitDefault(newDBName)

  console.time(`Drop Index DB ${oldDBName}`)
  setTimeout(() => {
    dropDatabase(oldDBName).then(res => console.timeEnd(`Drop Index DB ${oldDBName}`))
  }, 60000);
}

export const findOneByIdDataBasicUnit = async (id) => {
  return await findOne(getNameDbUnitDefault(), getNameDbUnitDefault(), id)
}

export const findManyByIdsDataBasicUnit = async (ids, allowEmptyItem = false) => {
  return await findMany(getNameDbUnitDefault(), getNameDbUnitDefault(), ids, allowEmptyItem)
}

export const getAllDataBasicUnit = async () => {
  return await getAll(getNameDbUnitDefault(), getNameDbUnitDefault())
}

const getNameDbUnitByItemNameNew = () => {
  return localStorage.getItem(DB_UNIT_BY_ITEM_NAME_NEW) || DB_UNIT_BY_ITEM_NAME_NEW
}
const setNameDbUnitByItemNameNew = (dbName) => {
  return localStorage.setItem(DB_UNIT_BY_ITEM_NAME_NEW, dbName)
}

// Item Name New
export const insertDataBasicUnitItemNameNew = async (data) => {
  const oldDBName = getNameDbUnitByItemNameNew()

  const newDBName = `${DB_UNIT_BY_ITEM_NAME_NEW}_${new Date().getTime()}`

  await createIndexedDB(newDBName, newDBName, 'itemNameNew')

  await insert(newDBName, newDBName, data)

  setNameDbUnitByItemNameNew(newDBName)

  console.time(`Drop Index DB ${oldDBName}`)
  setTimeout(() => {
    dropDatabase(oldDBName).then(res => console.timeEnd(`Drop Index DB ${oldDBName}`))
  }, 60000);
}

export const findOneByIdDataBasicUnitItemNameNew = async (id) => {
  return await findOne(getNameDbUnitByItemNameNew(), getNameDbUnitByItemNameNew(), id)
}

export const findManyByIdsDataBasicUnitItemNameNew = async (ids, allowEmptyItem = false) => {
  return await findMany(getNameDbUnitByItemNameNew(), getNameDbUnitByItemNameNew(), ids, allowEmptyItem)
}

export const getAllDataBasicUnitItemNameNew = async () => {
  return await getAll(getNameDbUnitByItemNameNew(), getNameDbUnitByItemNameNew())
}
