import {DASHBOARD_TYPES} from '@/constants/dashboard';
import { isInt } from '@/filters/number';
import {
  prepareChartDataEmissionByPeriodWithYearly,
  prepareChartDataEmissionByPeriodWithMonthly,
  prepareChartDataEmissionByCategoryWith,
  prepareChartDataEmissionByPeriodDetail,
  prepareDurationByCategoryWithYearly,
  prepareDurationByCategoryWithMonthly,
  maxValueEmissionsByCategoryWithYearly,
} from '@/concerns/transform-chart-data';
import {
  getEmissionsByPeriod,
  getEmissionsByCategory,
  getEmissionByPeriodDetail,
  getEmissionByCategoryDetail,
  getEmissionByCategoryDetailTop5,
} from '@/api/dashboard';
import {
  // defaultEmissionByPeriodWithYearly,
  // defaultEmissionByPeriodWithMonthly,
  defaultEmissionByCategory,
  mappingCategoryRowNumToScope,
} from '@/constants/dashboard';
import moment from 'moment';
import {prepareChartAxisY, calcCategoryPercent} from '@/concerns/chart';
import {getDurations} from '@/api/duration';
import {numberFormat} from '@/filters/number';
import i18n from '@/lang/i18n';

export const scope1Tooltip = [
  i18n.t('list_menu.tooltip_scope_1'),
];
export const scope2Tooltip = [
 i18n.t('list_menu.tooltip_scope_2')
];
export const scope3Tooltip = [
    i18n.t('list_menu.tooltip_scope_3_category_1'),
    i18n.t('list_menu.tooltip_scope_3_category_2'),
    i18n.t('list_menu.tooltip_scope_3_category_3'),
    i18n.t('list_menu.tooltip_scope_3_category_4'),
    i18n.t('list_menu.tooltip_scope_3_category_5'),
    i18n.t('list_menu.tooltip_scope_3_category_6'),
    i18n.t('list_menu.tooltip_scope_3_category_7'),
    i18n.t('list_menu.tooltip_scope_3_category_8'),
    i18n.t('list_menu.tooltip_scope_3_category_9'),
    i18n.t('list_menu.tooltip_scope_3_category_10'),
    i18n.t('list_menu.tooltip_scope_3_category_11'),
    i18n.t('list_menu.tooltip_scope_3_category_12'),
    i18n.t('list_menu.tooltip_scope_3_category_13'),
    i18n.t('list_menu.tooltip_scope_3_category_14'),
    i18n.t('list_menu.tooltip_scope_3_category_15'),
    i18n.t('list_menu.tooltip_scope_3_category_16'),
    i18n.t('list_menu.tooltip_scope_4_category_1')
];

const validateParams = (query) => {
  const isInValid = Object.values(query).some((item) => item === null || item === undefined);

  return {paramsValid: !isInValid};
};

const defaultState = () => {
  return {
    queryParams: {
      contractorId          : null,
      organizationalDivision: null,
      companyName           : null,
      branchId              : null,
      year                  : null,
      month                 : null,
      duration_id           : null,
    },
    branchIds  : [],
    // period
    selectedTypeForEmissionsByPeriod  : DASHBOARD_TYPES.duration,
    emissionsByPeriodWithYearly       : [],
    emissionsByPeriodWithMonthly      : [],
    selectedEmissionsByPeriod         : {},
    emissionsByPeriodDetail           : {},
    indexAtFirstBarByPeriodWithYearly : -1,
    indexAtFirstBarByPeriodWithMonthly: -1,
    // category
    selectedTypeForEmissionsByCategory       : DASHBOARD_TYPES.duration,
    emissionsByCategoryWithYearly            : defaultEmissionByCategory,
    emissionsByCategoryWithMonthly           : defaultEmissionByCategory,
    selectedEmissionsByCategoryWithYearly    : {},
    selectedEmissionsByCategoryWithMonthly   : {},
    emissionsByCategoryDetail                : {},
    emissionsByCategoryDetailTop5            : {},
    axisYByCategory                          : {
      max   : 1,
      min: 1,
      values: [],
    },
    durationByCategoryWithYearly             : [],
    durationByCategoryWithMonthly            : [],
    keepSelectedDurationByCategoryWithMonthly: {
      startIndex        : -1,
      selectedIndexMonth: -1,
      selectedIndexYear : -1,
    },
    keepSelectedDurationByCategoryWithYearly : -1,
    isEnableScope3                           : false,
    
    selectedEmissionsByCategoryDashboard     : {
      scope: null, // 1 | 2 | 3
      category: null, // 1 | 2 | 3 || 4 | 5 | 6 | 7,
      selectedTab: 0,
      selectedYear: null,
      selectedYearDetail: {},
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedHalfYearDetail: {},
      checkBoxHalfYearStatus: false, 
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedQuarterYearDetail: {},
      checkBoxQuarterYearStatus: false, 
      selectedMonthYear: null, // 1/2017
      selectedMonthYearDetail: {},
      checkBoxMonthYearStatus: false, 
    },
    selectedEmissionsByPeriodDashboard: {
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017
      checkBoxQuarterYearStatus: false, 
      checkBoxMonthYearStatus: false, 
    },
  };
};

const state = defaultState();

export const getters = {
  getQueryParamsForRequest          : (state) => {
    return {
      contractor_id          : state.queryParams.contractorId,
      organizational_division: state.queryParams.organizationalDivision,
      company_name           : state.queryParams.companyName,
      branch_id              : state.queryParams.branchId,
      country                : state.queryParams.country,
      layer_3                : state.queryParams.layer_3,
      layer_4                : state.queryParams.layer_4,
      layer_5                : state.queryParams.layer_5,
      layer_6                : state.queryParams.layer_6,
      type                   : state.selectedTypeForEmissionsByPeriod,
    };
  },
  getQueryParamsForRequestByCategory: (state) => {
    const defaultParams = {
      type: state.selectedTypeForEmissionsByCategory,
    };

    state.branchIds.forEach((v, k) => {
      defaultParams[`branch_id[${k}]`] = v;
    });

    if (state.selectedTypeForEmissionsByCategory === DASHBOARD_TYPES.duration) {
      return {
        ...defaultParams,
        duration_id: state.queryParams.duration_id,
      };
    }

    return {
      ...defaultParams,
      month: state.queryParams.month,
      year : state.queryParams.year,
    };
  },
  getQueryParams                    : (state) => state.queryParams,
  getBranchIds                      : (state) => state.branchIds,
  // period
  getSelectedTypeForEmissionsByPeriod: (state) => state.selectedTypeForEmissionsByPeriod,
  getEmissionsByPeriodWithYearly     : (state) => state.emissionsByPeriodWithYearly,
  getEmissionsByPeriodWithMonthly    : (state) => state.emissionsByPeriodWithMonthly,
  getQueryParamsForRequestEmissionsByPeriodDetail(state) {
    const defaultParams = {
      type       : state.selectedTypeForEmissionsByPeriod,
      duration_id: state.selectedEmissionsByPeriod.duration_id,
    };

    state.branchIds.forEach((v, k) => {
      defaultParams[`branch_id[${k}]`] = v;
    });

    if (state.selectedTypeForEmissionsByPeriod === DASHBOARD_TYPES.duration) {
      return {
        ...defaultParams,
      };
    }

    return {
      ...defaultParams,
      month      : Number(state.selectedEmissionsByPeriod.month),
      year       : Number(state.selectedEmissionsByPeriod.year),
      duration_id: state.selectedEmissionsByPeriod.duration_id,
    };
  },
  getEmissionsByPeriodDetail           : (state) => state.emissionsByPeriodDetail,
  getIndexAtFirstBarByPeriodWithYearly : (state) => state.indexAtFirstBarByPeriodWithYearly,
  getIndexAtFirstBarByPeriodWithMonthly: (state) => state.indexAtFirstBarByPeriodWithMonthly,
  // category
  getSelectedTypeForEmissionsByCategory: (state) => state.selectedTypeForEmissionsByCategory,
  getEmissionsByCategoryWithYearly     : (state) => state.emissionsByCategoryWithYearly,

  getEmissionsByCategoryWithYearlyScope1           : (state) => {
    if (state.axisYByCategory.values.length === 0) {
      const [maxVal, minVal, totalList] = maxValueEmissionsByCategoryWithYearly(state.emissionsByCategoryWithYearly);
      const [values, minY, maxY] = prepareChartAxisY({totalList:totalList});
      state.axisYByCategory = {
        max: maxY,
        min: minY,
        values,
      };
    }
    
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;

    return Object.values(state.emissionsByCategoryWithYearly.scope1).map((item, index) => {
      return {
        tooltip: scope1Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithYearlyScope2           : (state) => {
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;
    return Object.values(state.emissionsByCategoryWithYearly.scope2).map((item, index) => {
      return {
        tooltip: scope2Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithYearlyScope3           : (state) => {
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;

    return Object.values(state.emissionsByCategoryWithYearly.scope3).map((item, index) => {
      return {
        tooltip: scope3Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithYearlyScope4           : (state) => {
    const max = state.axisYByCategory.max;

    return Object.values(state.emissionsByCategoryWithYearly.scope4).map((item) => {
      return {
        ...item,
        period: ((Number(item.value) / max) * 100).toFixed(2),
      };
    });
  },
  getEmissionsByCategoryWithMonthly                : (state) => state.emissionsByCategoryWithMonthly,
  getEmissionsByCategoryWithMonthlyScope1          : (state) => {
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;

    return Object.values(state.emissionsByCategoryWithMonthly.scope1).map((item, index) => {
      return {
        tooltip: scope1Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithMonthlyScope2          : (state) => {
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;

    return Object.values(state.emissionsByCategoryWithMonthly.scope2).map((item, index) => {
      return {
        tooltip: scope2Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithMonthlyScope3          : (state) => {
    const max = state.axisYByCategory.max;
    const min = state.axisYByCategory.min;

    return Object.values(state.emissionsByCategoryWithMonthly.scope3).map((item, index) => {
      return {
        tooltip: scope3Tooltip[index],
        ...calcCategoryPercent(item, max, min),
      };
    });
  },
  getEmissionsByCategoryWithMonthlyScope4          : (state) => {
    const max = state.axisYByCategory.max;

    return Object.values(state.emissionsByCategoryWithMonthly.scope4).map((item) => {
      return {
        ...item,
        period: ((Number(item.value) / max) * 100).toFixed(2),
      };
    });
  },
  getQueryParamsForRequestEmissionsByCategoryDetail: (state) => {
    const defaultParams = {
      type: state.selectedTypeForEmissionsByCategory,
    };

    state.branchIds.forEach((v, k) => {
      defaultParams[`branch_id[${k}]`] = v;
    });

    if (state.selectedTypeForEmissionsByCategory === DASHBOARD_TYPES.duration) {
      return {
        ...defaultParams,
        row_num    : state.selectedEmissionsByCategoryWithYearly ? state.selectedEmissionsByCategoryWithYearly.row_num : 1,
        duration_id: state.queryParams.duration_id,
      };
    }

    const durationList = state.durationByCategoryWithYearly;
    const curDate      = moment(
      state.queryParams.year +
      '-' +
      (state.queryParams.month < 10 ? '0' + state.queryParams.month : state.queryParams.month),
    );
    for (let i in durationList) {
      const start = moment(durationList[i].original.start_at);
      const end   = moment(durationList[i].original.end_at);
      if (curDate.isSameOrAfter(start) && curDate.isSameOrBefore(end)) {
        return {
          ...defaultParams,
          row_num    : state.selectedEmissionsByCategoryWithMonthly.row_num,
          month      : state.queryParams.month,
          year       : state.queryParams.year,
          duration_id: durationList[i].value,
        };
      }
    }

    return {
      ...defaultParams,
      row_num: state.selectedEmissionsByCategoryWithMonthly.row_num,
      month  : state.queryParams.month,
      year   : state.queryParams.year,
    };
  },
  getSelectedEmissionsByCategoryWithYearly         : (state) => state.selectedEmissionsByCategoryWithYearly,
  getSelectedEmissionsByCategoryWithMonthly        : (state) => state.selectedEmissionsByCategoryWithMonthly,
  getEmissionsByCategoryDetail                     : (state) => state.emissionsByCategoryDetail,
  getEmissionsByCategoryDetailTop5                 : (state) => state.emissionsByCategoryDetailTop5,
  getAxisYByCategory                               : (state) => state.axisYByCategory,
  getDurationByCategoryWithYearly                  : (state) => state.durationByCategoryWithYearly,
  getDurationByCategoryWithMonthly                 : (state) => state.durationByCategoryWithMonthly,
  getKeepSelectedDurationByCategoryWithMonthly     : (state) => state.keepSelectedDurationByCategoryWithMonthly,
  getKeepSelectedDurationByCategoryWithYearly      : (state) => state.keepSelectedDurationByCategoryWithYearly,
  getIsEnableScope3                                : (state) => state.isEnableScope3,
};

const mutations = {
  setIsEnableScope3(state, value) {
    state.isEnableScope3 = value
  },
  changeQueryParams(state, newQueryParams) {
    let tmpQueryParams                    = state.queryParams;
    tmpQueryParams.organizationalDivision = null
    tmpQueryParams.companyName            = null
    tmpQueryParams.branchId               = null
    tmpQueryParams.country                = null
    tmpQueryParams.layer_3                = null
    tmpQueryParams.layer_4                = null
    tmpQueryParams.layer_5                = null
    tmpQueryParams.layer_6                = null
    state.queryParams                     = {
      ...tmpQueryParams,
      ...newQueryParams,
    };
  },
  changeBranchIds(state, newBranchIds) {
    state.branchIds = newBranchIds;
  },
  // period
  changeSelectedTypeForEmissionsByPeriod(state, newType) {
    state.selectedTypeForEmissionsByPeriod = newType;
  },
  changeEmissionsByPeriodWithYearly(state, newData) {
    state.emissionsByPeriodWithYearly = newData;
  },
  changeEmissionsByPeriodWithMonthly(state, newData) {
    state.emissionsByPeriodWithMonthly = newData;
  },
  changeSelectedEmissionsByPeriod(state, newData) {
    state.selectedEmissionsByPeriod = newData;
  },
  changeEmissionsByPeriodDetail(state, newData) {
    state.emissionsByPeriodDetail = newData;
  },
  clearDataEmissionByPeriod(state) {
    const defaultStateValue = defaultState();

    state.emissionsByPeriodWithYearly  = defaultStateValue.emissionsByPeriodWithYearly;
    state.emissionsByPeriodWithMonthly = defaultStateValue.emissionsByPeriodWithMonthly;
    state.selectedEmissionsByPeriod    = defaultStateValue.selectedEmissionsByPeriod;
    state.emissionsByPeriodDetail      = defaultStateValue.emissionsByPeriodDetail;
  },
  changeIndexAtFirstBarByPeriodWithYearly(state, newData) {
    state.indexAtFirstBarByPeriodWithYearly = newData;
  },
  changeIndexAtFirstBarByPeriodWithMonthly(state, newData) {
    state.indexAtFirstBarByPeriodWithMonthly = newData;
  },
  // category
  changeSelectedTypeForEmissionsByCategory(state, newType) {
    state.selectedTypeForEmissionsByCategory = newType;
  },
  changeEmissionsByCategoryWithYearly(state, newData) {
    state.emissionsByCategoryWithYearly = newData;
  },
  changeEmissionsByCategoryWithMonthly(state, newData) {
    state.emissionsByCategoryWithMonthly = newData;
  },
  changeSelectedEmissionsByCategoryWithYearly(state, newData) {
    state.selectedEmissionsByCategoryWithYearly = newData;
  },
  changeSelectedEmissionsByCategoryWithMonthly(state, newData) {
    state.selectedEmissionsByCategoryWithMonthly = newData;
  },
  changeEmissionsByCategoryDetail(state, newData) {
    state.emissionsByCategoryDetail = newData;
  },
  changeEmissionsByCategoryDetailTop5(state, newData) {
    state.emissionsByCategoryDetailTop5 = newData;
  },
  changeAxisYByCategory(state, newData) {
    state.axisYByCategory = newData;
  },
  clearDataEmissionByCategory(state) {
    const defaultStateValue = defaultState();

    state.emissionsByCategoryWithYearly  = defaultStateValue.emissionsByCategoryWithYearly;
    state.emissionsByCategoryWithMonthly = defaultStateValue.emissionsByCategoryWithMonthly;
    // state.selectedEmissionsByCategoryWithYearly = defaultStateValue.selectedEmissionsByCategoryWithYearly;
    // state.selectedEmissionsByCategoryWithMonthly = defaultStateValue.selectedEmissionsByCategoryWithMonthly;
    state.emissionsByCategoryDetail = defaultStateValue.emissionsByCategoryDetail;
    state.axisYByCategory           = defaultStateValue.axisYByCategory;
  },
  clearDataEmissionByCategoryTop5(state) {
    state.emissionsByCategoryDetailTop5 = {};
  },
  changeDurationByCategoryWithYearly(state, newData) {
    state.durationByCategoryWithYearly = newData;
  },
  changeDurationByCategoryWithMonthly(state, newData) {
    state.durationByCategoryWithMonthly = newData;
  },
  changeKeepSelectedDurationByCategoryWithMonthly(state, newData) {
    state.keepSelectedDurationByCategoryWithMonthly = {
      ...state.keepSelectedDurationByCategoryWithMonthly,
      ...newData,
    };
  },
  changeKeepSelectedDurationByCategoryWithYearly(state, newData) {
    state.keepSelectedDurationByCategoryWithYearly = newData;
  },
  // global
  clearData(state) {
    Object.assign(state, defaultState());
  },
  updateSelectedEmissionsByPeriodDashboard(state, newData) {
    state.selectedEmissionsByPeriodDashboard = newData;
  },
  updateSelectedEmissionsByCategoryDashboard(state, newData) {
    state.selectedEmissionsByCategoryDashboard = newData;
  },
};

const actions = {
  changeBranchIds({commit}, newBranchIds) {
    commit('changeBranchIds', newBranchIds);
  },
  // period
  async requestEmissionByPeriod({
                                  commit,
                                  getters,
                                  state,
                                  dispatch
                                }) {
    const params         = getters.getQueryParamsForRequest;
    const requiredParams = (({
                               contractor_id,
                               type
                             }) => ({
      contractor_id,
      type
    }))(params);

    const validateResult = validateParams(requiredParams);

    if (!validateResult.paramsValid) {
      return;
    }

    await getEmissionsByPeriod(params).then((response) => {
      commit('setIsEnableScope3', response.display_scope_3)
      commit(
        'changeBranchIds',
        response.branch_ids.map((item) => +item),
      );
      // dispatch('requestEmissionByCategory');
      if (state.selectedTypeForEmissionsByPeriod === DASHBOARD_TYPES.duration) {
        // set data for yearly type
        const data = prepareChartDataEmissionByPeriodWithYearly(response.data);
        commit('changeEmissionsByPeriodWithYearly', data);
      } else {
        // set data for monthly type
        const data = prepareChartDataEmissionByPeriodWithMonthly(response.data);
        commit('changeEmissionsByPeriodWithMonthly', data);
      }
    });
  },
  changeSelectedTypeForEmissionsByPeriod({commit}, newType) {
    commit('changeSelectedTypeForEmissionsByPeriod', newType);
  },
  changeSelectedEmissionsByPeriod({commit}, newData) {
    commit('changeSelectedEmissionsByPeriod', newData);
  },
  requestEmissionsByPeriodDetail({
                                   commit,
                                   getters,
                                   state
                                 }) {
    const params         = getters.getQueryParamsForRequestEmissionsByPeriodDetail;
    const validateResult = validateParams(params);

    if (!validateResult.paramsValid) {
      console.warn(params);
      return;
    }

    getEmissionByPeriodDetail(params).then((res) => {
      let duration = '';
      if (state.selectedTypeForEmissionsByPeriod === DASHBOARD_TYPES.duration) {
        const startAt = moment(state.selectedEmissionsByPeriod.start_at, 'YYYY/M');
        const endAt   = moment(state.selectedEmissionsByPeriod.end_at, 'YYYY/M');

        duration = `${startAt.format('YYYY年M月')} - ${endAt.format('YYYY年M月')}`;
      } else {
        duration = `${state.selectedEmissionsByPeriod.year}年${numberFormat(state.selectedEmissionsByPeriod.month)}月`;
      }

      commit('changeEmissionsByPeriodDetail', {
        ...prepareChartDataEmissionByPeriodDetail(res.data),
        duration,
      });
    });
  },
  clearDataEmissionByPeriod({commit}) {
    commit('clearDataEmissionByPeriod');
  },
  changeIndexAtFirstBarByPeriodWithYearly({commit}, newIndex) {
    commit('changeIndexAtFirstBarByPeriodWithYearly', newIndex);
  },
  changeIndexAtFirstBarByPeriodWithMonthly({commit}, newIndex) {
    commit('changeIndexAtFirstBarByPeriodWithMonthly', newIndex);
  },
  // category
  requestEmissionByCategory({
                              commit,
                              getters,
                              state,
                              dispatch
                            }) {
    const params         = getters.getQueryParamsForRequestByCategory;
    const validateResult = validateParams(params);
    if (!validateResult.paramsValid) {
      console.warn(params);
      commit('clearDataEmissionByCategory');
      return;
    }
    getEmissionsByCategory(params)
      .then((res) => {
        const [data, maxVal, minVal, totalList] = prepareChartDataEmissionByCategoryWith(res, state.selectedTypeForEmissionsByCategory);
        const [values, min, max] = prepareChartAxisY({totalList:totalList});

        commit('changeAxisYByCategory', {
          max,
          min,
          values,
        });

        if (state.selectedTypeForEmissionsByCategory === DASHBOARD_TYPES.duration) {
          // set data for yearly type
          const selectedData = Object.prototype.hasOwnProperty.call(
            state.selectedEmissionsByCategoryWithYearly,
            'scope',
          )
            ? data['scope' + state.selectedEmissionsByCategoryWithYearly.scope][
              state.selectedEmissionsByCategoryWithYearly.row_num
              ]
            : null;
          commit('changeEmissionsByCategoryWithYearly', data);
          commit('changeSelectedEmissionsByCategoryWithYearly', selectedData ? selectedData.original : null);
        } else {
          // set data for monthly type
          const selectedData =
                  data['scope' + state.selectedEmissionsByCategoryWithMonthly.scope][
                    state.selectedEmissionsByCategoryWithMonthly.row_num
                    ];
          commit('changeEmissionsByCategoryWithMonthly', data);
          commit('changeSelectedEmissionsByCategoryWithMonthly', selectedData ? selectedData.original : null);
        }

        dispatch('requestEmissionsByCategoryDetail');
      })
      .catch((_) => {
        commit('clearDataEmissionByCategory');
      });
  },
  changeSelectedTypeForEmissionsByCategory({commit}, newType) {
    commit('changeSelectedTypeForEmissionsByCategory', newType);
  },
  changeSelectedEmissionsByCategoryWithYearly({commit}, newData) {
    if (newData) {
      commit('changeSelectedEmissionsByCategoryWithYearly', newData);
    }
  },
  changeSelectedEmissionsByCategoryWithMonthly({commit}, newData) {
    if (newData) {
      commit('changeSelectedEmissionsByCategoryWithMonthly', newData);
    }
  },
  requestEmissionsByCategoryDetail({
                                     commit,
                                     state,
                                     getters
                                   }) {
    const params = getters.getQueryParamsForRequestEmissionsByCategoryDetail;
                                  
    const validateResult = validateParams(params);

    if (!validateResult.paramsValid) {
      console.warn(params);
      return;
    }

    const isTypeDuration = state.selectedTypeForEmissionsByCategory === DASHBOARD_TYPES.duration;
    const rowNum         = isTypeDuration
      ? state.selectedEmissionsByCategoryWithYearly
        ? state.selectedEmissionsByCategoryWithYearly.row_num
        : 1
      : state.selectedEmissionsByCategoryWithMonthly.row_num;
    const scopeName      = mappingCategoryRowNumToScope[rowNum];
    const categoryName   = defaultEmissionByCategory[scopeName][rowNum]['title'];
    const dataInChartBar = isTypeDuration
      ? {...state.selectedEmissionsByCategoryWithYearly}
      : {...state.selectedEmissionsByCategoryWithMonthly};

    getEmissionByCategoryDetail(params)
      .then((res) => {
        let valueEmission = isTypeDuration ? dataInChartBar.total_duration : dataInChartBar.total_month;
        let value = isInt(Number(valueEmission)) ? Number(valueEmission) : Number(Number(valueEmission).toFixed(2));
        value = isNaN(value) ? "-" : value;
        commit('changeEmissionsByCategoryDetail', {
          ...res,
          ...dataInChartBar,
          value   : value,
          rowNum,
          scopeName,
          categoryName,
          original: dataInChartBar,
        });
      })
      .catch((errors) => {
        console.error(errors);
        throw errors;
      });
  },
  clearDataEmissionByCategory({commit}) {
    commit('clearDataEmissionByCategory');
  },
  requestDurationByCategory({commit}) {
    getDurations().then((res) => {
      const durationsByYearData = prepareDurationByCategoryWithYearly(res.data);
      commit('changeDurationByCategoryWithYearly', durationsByYearData);

      const durationsByMonthlyData = prepareDurationByCategoryWithMonthly(res.data);
      commit('changeDurationByCategoryWithMonthly', durationsByMonthlyData);
    });
  },
  requestEmissionsByCategoryDetailTop5({
                                         commit,
                                         state,
                                         getters
                                       }) {
    const params = getters.getQueryParamsForRequestEmissionsByCategoryDetail;

    const validateResult = validateParams(params);

    if (!validateResult.paramsValid) {
      console.warn(params);
      return;
    }

    getEmissionByCategoryDetailTop5(params)
      .then((res) => {
        commit('changeEmissionsByCategoryDetailTop5', {
          ...res.result,
        });
      })
      .catch((errors) => {
        console.error(errors);
        commit('clearDataEmissionByCategoryTop5');
        throw errors;
      });
  },
  changeKeepSelectedDurationByCategoryWithMonthly({commit}, newData) {
    commit('changeKeepSelectedDurationByCategoryWithMonthly', newData);
  },
  changeKeepSelectedDurationByCategoryWithYearly({commit}, newData) {
    commit('changeKeepSelectedDurationByCategoryWithYearly', newData);
  },
  // filter
  changeQueryParams({commit}, newQueryParams) {
    commit('changeQueryParams', newQueryParams);
  },

  clearData({commit}) {
    commit('clearData');
  },

  setIsEnableScope3({commit}, value) {
    commit('setIsEnableScope3', value);
  },
  updateSelectedEmissionsByPeriodDashboard({commit}, value) {
    commit('updateSelectedEmissionsByPeriodDashboard', value);
  },
  updateSelectedEmissionsByCategoryDashboard({commit}, value) {
    commit('updateSelectedEmissionsByCategoryDashboard', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
