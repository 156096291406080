<template>
  <v-btn @mousedown="handleBtnClick" class="common-btn" :disabled="isDisable" depressed :class="type" :loading="loading"
    @mouseover="changeTooltipImage($event)"
    @mouseleave="changeTooltipImage($event, 'mouseleave')"
  >
    <img v-if="icon" class="icon" :src="getIcon" alt="" />
    <slot class="icon"></slot>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    iconHover: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleBtnClick() {
      this.$emit('action');
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        this.$emit('mouseleave')
      } else {
        this.$emit('mouseover')
      }
      if (this.getHoverIcon === '') return;
      const img = event.target.querySelector('img');
      if (img) {
        if (type === 'mouseleave') {
          img.src = this.getIcon;
        } else {
          img.src = this.getHoverIcon;
        }
      }
    },
  },
  computed: {
    getIcon() {
      return require(`@/assets/images/${this.icon}`);
    },
    getHoverIcon() {
      if (this.iconHover === '') return '';
      return require(`@/assets/images/${this.iconHover}`);
    }
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 8px !important;
}
</style>
